import { NavLink } from "react-router-dom";

export function NavigationLinks({
	tel,
	closeMenu,
}: {
	tel: string;
	closeMenu?: () => void;
}) {
	return (
		<>
			<div className="font-bold hover:opacity-90" onClick={closeMenu}>
				<a href="./#o-nas" referrerPolicy="origin">
					O nas
				</a>
			</div>
			<div className="font-bold hover:opacity-90">
				<NavLink to="/oferta">Oferta</NavLink>
			</div>
			<div className="font-bold hover:opacity-90" onClick={closeMenu}>
				<a href="#kontakt">Kontakt</a>
			</div>
			<div className="font-bold hover:opacity-90" onClick={closeMenu}>
				<NavLink
					className="inline-block px-4 py-3 rounded-full bg-white text-[var(--main-color)] hover:bg-[var(--main-color)] hover:text-white transition-colors duration-300 hover:shadow-[0_0_5px_1px_white]"
					to={`tel:${tel}`}
				>
					Tel: {tel}
				</NavLink>
			</div>
		</>
	);
}
