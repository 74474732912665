import { useEffect, useRef, useState } from "react";
import type { StrapiReturn } from "../../types/strapi-return";
import type { ProductType } from "../../types/strapi-types";

export function Product({ data }: { data: StrapiReturn<ProductType> }) {
	const [isExpanded, setIsExpanded] = useState(false);

	const productRef = useRef<HTMLDivElement>(null);

	const { title, items } = data.attributes;

	const itemsList = items.split("\n");

	useEffect(() => {
		function handleMouse(event: MouseEvent) {
			if (!isExpanded) {
				return;
			}

			if (!event.target) {
				return;
			}

			if (
				(event.target as Element).closest(
					`#products-${title.replaceAll(" ", "_")}`
				)
			) {
				return;
			}

			setIsExpanded(false);
		}

		document.addEventListener("click", handleMouse);
		return () => document.removeEventListener("click", handleMouse);
	}, [title, isExpanded]);

	useEffect(() => {
		if (isExpanded) {
			setTimeout(() => {
				productRef.current?.scrollIntoView({
					behavior: "smooth",
					block: "start",
				});
			}, 0);
		}
	}, [isExpanded]);

	return (
		<div ref={productRef} id={`products-${title.replaceAll(" ", "_")}`}>
			<button
				className="flex justify-between items-center p-2 border-[var(--main-color)] border-2 relative cursor-pointer w-full"
				onClick={() => setIsExpanded((prev) => !prev)}
			>
				<h4 className="text-lg font-semibold text-start">{title}</h4>

				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="none"
					stroke="var(--main-color)"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
					className={`w-8 h-8 transition-transform ${
						isExpanded ? "rotate-180" : ""
					}`}
				>
					<path d="m18 15-6-6-6 6" />
				</svg>
			</button>

			{isExpanded &&
				itemsList.map((item) => (
					<div
						key={item}
						className="p-2 border-[var(--main-color)] border-b-2 border-x-2"
					>
						{item}
					</div>
				))}
		</div>
	);
}
